export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/admin": [6,[2,3]],
		"/auth": [17],
		"/(app)/c/[id]": [7,[2]],
		"/error": [18],
		"/s/[id]": [19],
		"/(app)/workspace": [8,[2,4]],
		"/(app)/workspace/documents": [9,[2,4]],
		"/(app)/workspace/models": [10,[2,4]],
		"/(app)/workspace/models/create": [11,[2,4]],
		"/(app)/workspace/models/edit": [12,[2,4]],
		"/(app)/workspace/playground": [13,[2,4]],
		"/(app)/workspace/prompts": [14,[2,4]],
		"/(app)/workspace/prompts/create": [15,[2,4]],
		"/(app)/workspace/prompts/edit": [16,[2,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';